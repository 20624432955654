import { BaseModel } from "@domain";


export type orthopError = {
	date: string;
	agencyId: number;
	dossierId: number;
	patientId: number;
	patientInsee: string;
	patientBirthdate: string;
	patientFirstname: string;
	patientLastname: string;
	operationType: string;
	orthopIds: string;
	errorType: string;
	errorMessage: string;
};

export class OrthopError implements BaseModel<orthopError> {
	constructor(orthopError: orthopError) {
		this._date = orthopError.date;
		this._agencyId = orthopError.agencyId;
		this._dossierId = orthopError.dossierId;
		this._patientId = orthopError.patientId;
		this._patientInsee = orthopError.patientInsee;
		this._patientBirthdate = orthopError.patientBirthdate;
		this._patientFirstname = orthopError.patientFirstname;
		this._patientLastname = orthopError.patientLastname;
		this._operationType = orthopError.operationType;
		this._orthopIds = orthopError.orthopIds;
		this._errorType = orthopError.errorType;
		this._errorMessage = orthopError.errorMessage;
	}

	private _date: string;

	// GETTERS
	get date(): string {
		return this._date;
	}

	set date(value: string) {
		this._date = value;
	}

	private _agencyId: number;

	get agencyId(): number {
		return this._agencyId;
	}

	set agencyId(value: number) {
		this._agencyId = value;
	}

	private _dossierId: number;

	get dossierId(): number {
		return this._dossierId;
	}

	set dossierId(value: number) {
		this._dossierId = value;
	}

	private _patientId: number;

	get patientId(): number {
		return this._patientId;
	}

	set patientId(value: number) {
		this._patientId = value;
	}

	private _patientInsee: string;

	get patientInsee(): string {
		return this._patientInsee;
	}

	set patientInsee(value: string) {
		this._patientInsee = value;
	}

	private _patientBirthdate: string;

	get patientBirthdate(): string {
		return this._patientBirthdate;
	}

	set patientBirthdate(value: string) {
		this._patientBirthdate = value;
	}

	private _patientFirstname: string;

	get patientFirstname(): string {
		return this._patientFirstname;
	}

	set patientFirstname(value: string) {
		this._patientFirstname = value;
	}

	private _patientLastname: string;

	get patientLastname(): string {
		return this._patientLastname;
	}

	set patientLastname(value: string) {
		this._patientLastname = value;
	}

	// SETTERS

	private _operationType: string;

	get operationType(): string {
		return this._operationType;
	}

	set operationType(value: string) {
		this._operationType = value;
	}

	private _orthopIds: string;

	get orthopIds(): string {
		return this._orthopIds;
	}

	set orthopIds(value: string) {
		this._orthopIds = value;
	}

	private _errorType: string;

	get errorType(): string {
		return this._errorType;
	}

	set errorType(value: string) {
		this._errorType = value;
	}

	private _errorMessage: string;

	get errorMessage(): string {
		return this._errorMessage;
	}

	set errorMessage(value: string) {
		this._errorMessage = value;
	}

	asObject(): orthopError {
		return {
			date: this._date,
			agencyId: this._agencyId,
			dossierId: this._dossierId,
			patientId: this._patientId,
			patientInsee: this._patientInsee,
			patientBirthdate: this._patientBirthdate,
			patientFirstname: this._patientFirstname,
			patientLastname: this._patientLastname,
			operationType: this._operationType,
			orthopIds: this._orthopIds,
			errorType: this._errorType,
			errorMessage: this._errorMessage,
		};
	}
}
