import { Injectable } from '@angular/core';
import { DTO_amc } from '../types/DTO_dashboard.types';
import { BaseAdapter, getEnumKeyByValue } from '@infrastructure';
import { AmcError } from '../../../domain/dashboard/types/amcError';
import { amcErrorType } from '../../../domain/dashboard/enums/amc-error-status.enum';

@Injectable({
	providedIn: 'root',
})
export class AmcErrorAdapter implements BaseAdapter<DTO_amc, AmcError> {
	adapt(dto: DTO_amc): AmcError {
		return new AmcError({
			id: dto.id,
			agencyId: dto.agencyId,
			dateLog: dto.dateLog,
			patientLastname: dto.patientLastName,
			patientFirstname: dto.patientFirstName,
			patientId: dto.patientId,
			errorMessage: dto.errorMessage,
			isRead: dto.isRead,
			errorStatus: amcErrorType[getEnumKeyByValue(dto.errorStatus, amcErrorType) as keyof typeof amcErrorType],
		});
	}
}
