import { BaseModel } from "@domain";

export type amcError = {
	id: number;
	agencyId: number;
	dateLog: string;
	patientLastname: string;
	patientFirstname: string;
	patientId: number;
	errorMessage: string;
	isRead: boolean;
	errorStatus: string;
};

export class AmcError implements BaseModel<amcError> {
	constructor(amcError: amcError) {
		this._id = amcError.id;
		this._agencyId = amcError.agencyId;
		this._dateLog = amcError.dateLog;
		this._patientLastname = amcError.patientLastname;
		this._patientFirstname = amcError.patientFirstname;
		this._patientId = amcError.patientId;
		this._errorMessage = amcError.errorMessage;
		this._isRead = amcError.isRead;
		this._errorStatus = amcError.errorStatus;
	}

	private _id: number;
	private _agencyId: number;
	private _dateLog: string;
	private _patientLastname: string;
	private _patientFirstname: string;
	private _patientId: number;
	private _errorMessage: string;
	private _isRead: boolean
	private _errorStatus: string;

	// GETTERS
	get id(): number {
		return this._id;
	}
	get dateLog(): string {
		return this._dateLog;
	}
	get agencyId(): number {
		return this._agencyId;
	}
	get patientLastname(): string {
		return this._patientLastname;
	}
	get patientFirstname(): string {
		return this._patientFirstname;
	}
	get patientId(): number {
		return this._patientId;
	}
	get errorMessage(): string {
		return this._errorMessage;
	}
	get isRead(): boolean {
		return this._isRead;
	}
	get errorStatus(): string {
		return this._errorStatus;
	}
	
	// SETTERS
	set id(value: number) {
		this._id = value;
	}
	set dateLog(value: string) {
		this._dateLog = value;
	}
	set agencyId(value: number) {
		this._agencyId = value;
	}
	set patientLastname(value: string) {
		this._patientLastname = value;
	}
	set patientFirstname(value: string) {
		this._patientFirstname = value;
	}
	set patientId(value: number) {
		this._patientId = value;
	}
	set errorMessage(value: string) {
		this._errorMessage = value;
	}
	set isRead(value: boolean) {
		this._isRead = value;
	}
	set errorStatus(value: string) {
		this._errorStatus = value;
	}

	asObject(): amcError {
		return {
			id: this._id,
			agencyId: this._agencyId,
			dateLog: this._dateLog,
			patientLastname: this._patientLastname,
			patientFirstname: this._patientFirstname,
			patientId: this._patientId,
			errorMessage: this._errorMessage,
			isRead: this._isRead,
			errorStatus: this._errorStatus
		}
	}
}
