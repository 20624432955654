import { Injectable } from '@angular/core';
import { take } from 'rxjs';
import { HasStore, paginatedResult } from '@application';
import { searchAmcErrorParams, searchOrthopErrorParams } from '../../domain/dashboard/types/dashboard-manager.types';
import { OrthopError } from '../../domain/dashboard/types/orthopError';
import { DashboardRepository } from '../../repository/dashboard.repository'
import { AmcError } from '../../domain/dashboard/types/amcError';

export type dashboardManagerState = {
	orthopErrors: paginatedResult<OrthopError[] | null>,
	amcErrors: paginatedResult<AmcError[] | null>,
	lastOrthopSyncDate: string | null,
};

@Injectable({
	providedIn: 'root',
})
export default class DashboardManager extends HasStore<dashboardManagerState> {
	constructor(
		private readonly dashboardrepository: DashboardRepository,
	) {
		super(
			{
				orthopErrors: {
					data: null,
					pagination: {
						total: 0,
						page: 1,
						limit: 10,
						nbTotalPage: 0,
					},
				},
				amcErrors: {
					data: null,
					pagination: {
						total: 0,
						page: 1,
						limit: 10,
						nbTotalPage: 0,
					},
				},
				lastOrthopSyncDate: null,

			},
			'orthop',
		);
	}



	retrieveOrthopErrors(params: searchOrthopErrorParams) {
		return this.dashboardrepository
			.listOrthopErrors(params, this.store.value.orthopErrors.pagination)
			.pipe(take(1))
			.subscribe(result => {
				this.store.update(state => {
					return {
						...state,
						orthopErrors: result,
					};
				});
			});
	}

	retrieveLastSyncDateOrthop(agencyId: number) {
		return this.dashboardrepository
			.getLastSyncDateOrthop(agencyId)
			.pipe(take(1))
			.subscribe(date => {
				this.store.update(state => {
					return {
						...state,
						lastOrthopSyncDate: date,
					};
				});
			});
	}

	retrieveAmcErrors(params: searchAmcErrorParams) {
		return this.dashboardrepository
			.listAMCErrors(params)
			.pipe(take(1))
			.subscribe(result => {
				this.store.update(state => {
					return {
						...state,
						amcErrors: result,
					};
				});
			});
	}
}
