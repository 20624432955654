import { BaseHttpRepository } from '../../../../../core/infrastructure/src/lib/core/repository/base-http.repository';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { paginatedResult, pagination } from '@application';
import { DTO_amc, DTO_orthop, DTO_search_amc, DTO_search_orthop } from '../infrastructure/dashboard/types/DTO_dashboard.types';
import { OrthopErrorAdapter } from '../infrastructure/dashboard/adapters/orthopError.adapter';
import { AmcErrorAdapter } from '../infrastructure/dashboard/adapters/amcError.adapter';
import { AmcErrorSearchAdapter } from '../infrastructure/dashboard/adapters/amcErrorSearch.adapter';
import { OrthopErrorSearchAdapter } from '../infrastructure/dashboard/adapters/orthopErrorSearch.adapter';
import { searchAmcErrorParams, searchOrthopErrorParams } from '../domain/dashboard/types/dashboard-manager.types';
import { OrthopError } from '../domain/dashboard/types/orthopError';
import { AmcError } from '../domain/dashboard/types/amcError';

@Injectable({ providedIn: 'root' })
export class DashboardRepository extends BaseHttpRepository {
	constructor(
		private readonly orthopErrorsAdapter: OrthopErrorAdapter,
		private readonly orthopErrorsSearchAdapter: OrthopErrorSearchAdapter,
		private readonly amcoErrorsSearchAdapter: AmcErrorSearchAdapter,
		private readonly amcErrorsAdapter: AmcErrorAdapter,
	) {
		super();
	}

	listOrthopErrors(params: searchOrthopErrorParams, pagination: pagination): Observable<paginatedResult<OrthopError[]>> {
		const dataToSend: DTO_search_orthop = this.orthopErrorsSearchAdapter.adapt(params);

		return this.paginatedGet<DTO_orthop[]>(`${this.apiUrl}Orthop/SynchroErrors`, dataToSend, { 'X-Loader-Key': 'orthop-errors' }).pipe(
			map((errors: paginatedResult<DTO_orthop[]>) => {
				return {
					data: errors.data.map(error => this.orthopErrorsAdapter.adapt(error)),
					pagination: errors.pagination,
				};
			}),
		);
	}

	getLastSyncDateOrthop(agencyId: number): Observable<string> {
		return this.http.get<string>(`${this.orthopUrl}GetLastSynchroExecutedTime/${agencyId}`, { headers: { 'X-Loader-Key': 'orthop-errors' } }).pipe(
			map((date: string | null) => {
				return date == null ? '' : date;
			}),
		);
	}

	listAMCErrors(params: searchAmcErrorParams): Observable<paginatedResult<AmcError[]>> {
		const dataToSend: DTO_search_amc = this.amcoErrorsSearchAdapter.adapt(params);

		return this.paginatedGet<DTO_amc[]>(`${this.apiUrl}Patient/DocumentIA/Mutuelle/Logs`, dataToSend, { 'X-Loader-Key': 'amc-errors' }).pipe(
			map((errors: paginatedResult<DTO_amc[]>) => {
				return {
					data: errors.data.map(error => this.amcErrorsAdapter.adapt(error)),
					pagination: errors.pagination,
				};
			}),
		);
	}
}
