import { Injectable } from '@angular/core';
import { DTO_search_amc } from '../types/DTO_dashboard.types';
import { BaseAdapter } from '@infrastructure';
import { searchAmcErrorParams } from '../../../domain/dashboard/types/dashboard-manager.types';

@Injectable({
	providedIn: 'root',
})
export class AmcErrorSearchAdapter implements BaseAdapter<searchAmcErrorParams, DTO_search_amc> {
	adapt(params: searchAmcErrorParams): DTO_search_amc {
		return {
			agencyId: params.agencyId,
			patientFirstName: params.patientFirstname,
			patientLastName: params.patientLastname,
			errorStatus: params.errorStatus,
			PageNumber: params.pageNumber,
			PageSize: params.pageSize
		} as DTO_search_amc;
	}
}
