export enum orthopErrorType {
	PatientBirthDateNotMatching = 'Dates de naissance différentes',
	OrthopDuplicate = 'Doublon Orthop',
	PatientWithoutBrithdate = 'Patient Oxyweb sans date de naissance',
	PatientBrithdateMissing = 'Dates de naissance Oxyweb manquante',
	DossierWithoutPatient = 'Dossier Oxyweb sans patient',
	NoOrthopMatches = 'Aucune correspondance Orthop',
	PatientInseeMissing = 'Patient Oxyweb sans numéro de sécu',
	DossierTooOldForExport = 'Dossier trop ancien pour export',
	PatientWithoutSecu = 'Patient Oxyweb sans sécu',
	PatientWithInseeInvalid = 'Patient Oxyweb avec numéro de sécu incorrect',
	PrescriptionNotFoundInOrthop = 'Prescription introuvable sur Orthop',
}
