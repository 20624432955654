import { Injectable } from '@angular/core';
import { BaseAdapter } from '@infrastructure';
import { DTO_orthop } from '../types/DTO_dashboard.types';
import { OrthopError } from '../../../domain/dashboard/types/orthopError';
import { orthopOperationType } from '../../../domain/dashboard/enums/orthop-operation-type.enum';
import { orthopErrorType } from '../../../domain/dashboard/enums/orthop-error-status.enum';

@Injectable({
	providedIn: 'root',
})
export class OrthopErrorAdapter implements BaseAdapter<DTO_orthop, OrthopError> {
	adapt(dto: DTO_orthop): OrthopError {
		return new OrthopError({
			date: dto.date,
			agencyId: dto.agencyId,
			dossierId: dto.dossierId,
			patientId: dto.patientId,
			patientInsee: dto.patientInsee,
			patientBirthdate: dto.patientBirthdate,
			patientFirstname: dto.patientFirstName,
			patientLastname: dto.patientLastName,
			operationType: orthopOperationType[dto.errorOperation as keyof typeof orthopOperationType],
			orthopIds: dto.errorOrthopIds,
			errorType: orthopErrorType[dto.errorType as keyof typeof orthopErrorType],
			errorMessage: dto.errorMessage,
		});
	}
}
