
import { Injectable } from '@angular/core';
import { searchOrthopErrorParams } from '../../../domain/dashboard/types/dashboard-manager.types';
import { DTO_search_orthop } from '../types/DTO_dashboard.types';
import { BaseAdapter } from '@infrastructure';

@Injectable({
	providedIn: 'root',
})
export class OrthopErrorSearchAdapter implements BaseAdapter<searchOrthopErrorParams, DTO_search_orthop> {
	adapt(params: searchOrthopErrorParams): DTO_search_orthop {
		return {
			PatientFirstName: params.firstname,
			PatientLastName: params.lastname,
			ErrorType: params.errorType,
			ErrorOperation: params.errorOperation,
			AgencyId: params.agencyId,
			PageNumber: params.pageNumber,
			PageSize: params.pageSize,
		} as DTO_search_orthop;
	}
}
